import { withFooter } from '@/components/molecules/Footer'
import Helmet from '@/components/molecules/Helmet'
import PrivacyPolicy from '@/components/template/Info/PrivacyPolicy'
import theme from '@/theme'
import React, { CSSProperties } from 'react'

const styles: { [key: string]: CSSProperties } = {
    container: {
        fontFamily: theme.typography.fontFamily,
    },
    title: {
        margin: '30px auto',
        maxWidth: 1600,
        fontSize: 24,
        fontWeight: 600,
        color: theme.colors.primaryN,
    },
}

const PrivacyPolicyOverview = () => {
    return (
        <div style={styles.container}>
            <Helmet />
            <h4 style={styles.title}>プライバシーポリシー</h4>
            <PrivacyPolicy />
        </div>
    )
}

export default withFooter(PrivacyPolicyOverview)
